body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 8px !important; /* Set the width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: #f1f1f1 !important; /* Set the track color */
  border-radius: 10px !important; /* Optional: round the corners of the track */
}

*::-webkit-scrollbar-thumb {
  background: #4f97a1 !important; /* Set the thumb color */
  border-radius: 10px !important; /* Optional: round the corners of the thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background: #45858d !important; /* Slightly darker color on hover */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
