.otp-items{
    max-width: 40px;
}

.main-form-container{
    max-width: 400px;
    background-color: red;
}


/* For Webkit browsers (Chrome, Safari, Edge) */
.otp-items::-webkit-outer-spin-button,
.otp-items::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-btn{
    border-radius: 30px;
    background-color: rgb(44, 153, 161);
    border: 2px solid rgb(44, 153, 161);
    color: white;
    cursor: pointer;
    outline: none;
    box-shadow: rgb(204, 204, 204) 0px 0px 17px 6px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    padding: 5px 10px;
}

/* For Firefox */
.otp-items {
  -moz-appearance: textfield;
}

/* Optional: Style to make the input field consistent across all browsers */
.otp-items {
  appearance: none; /* For other browsers */
  -moz-appearance: none; /* For Firefox */
  -webkit-appearance: none; /* For Webkit browsers */
}

.form-container{
  box-shadow: rgb(204, 204, 204) 0px 0px 17px 6px;
  border-radius: 25px;
}
