.package-card {
    text-align: center;
    padding: 0px 15px;
    /* border: 1px solid #ddd; */
    border-radius: 16px;
    background-color: #fff;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.package-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.seller-product-buyer-card{
    font-family: Rubik !important;
}

.dashboard-seller-body-heading{
    font-size: 16px !important;
    font-family: Rubik !important;
    color: #000 !important;
}

.seller-dashboard-mesasge-card{
    padding: 15px 28px !important;
    margin-top: 15px !important;
    box-shadow: none !important;
}
.seller-dashboard-mesasge-card-inner{
    padding: 0px !important;
}

.font-weight-800{
    font-weight: 800;
}
.font-size-14{
    font-size: 14px;
}
.font-size-12{
    font-size: 12px;
}

.card-body-khata-top-clients{
    padding: 20px 0px !important;
}

.card-khata-rightisdebar-rounded{
    border-radius: 20px !important;
}

.dashboard-seller-body-para{
    font-size: 14px !important;
    font-family: Rubik !important;
    color: #000 !important;
    margin-top: 9px;
    letter-spacing: 2px;
    line-height: 1;
}

.package-icon {
    font-size: 2rem;
    margin-bottom: 10px;
}

.banner-seller-dashboard {
    background-image: url('../images//seller-dashboard-banner.png');
    background-repeat: no-repeat;
    /* Prevent image from repeating */
    background-size: cover;
    /* Make the image cover the full width (and height) */
    background-position: center center;
    /* Center the image within the element */
    width: 100%;
    /* Ensure the element covers full width */
    height: 120px;
    border-radius: 16px;
    padding: 20px;
}

.seller-product-buyer-card {
    padding: 0px !important;
}

.seller-product-buyer-card-header {
    background-color: #1B77B4;
    display: flex;
    justify-content: space-between;
    /* padding: 10px 10px; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* letter-spacing: 0.2px; */
    line-height: 1;
}

.seller-dashboard-card-divider {
    border-bottom: 1px solid #D1D0D0;
    /* Bottom border with the color */
    width: 100%;
    line-height: 1.1;
    letter-spacing: 0.2px;
    /* Ensures the divider spans the full width of its container */
    /* margin: 20px 0; */
}
.seller-dashboard-carts-divider {
    border-bottom: 1px solid #D1D0D0;
    width: 85%;
    margin: 0px auto;
}

.d-flex .seller-product-buyer-card-header div,
span {
    color: white;
    font-size: 12px;
}

.d-flex .seller-product-buyer-card-header div,
nav span {
    color: grey;
    font-size: 12px;
}

.d-flex .seller-product-buyer-card-header div,
span:hover {
    color: #0BA9ED;
}

.banner-seller-dashboard p {
    font-size: 12px;
}

.banner-seller-dashboard span {
    font-size: 16px;
}

.classic {
    color: green;
}

.bronze {
    color: brown;
}

.silver {
    color: gray;
}

.gold {
    color: gold;
}

.diamond {
    color: deepskyblue;
}