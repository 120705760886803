/* ==================================================  Footer ============================*/

.footer-container{
    background-color: #F0F3F8;
}

.footer-text{
    font-size: 13px;
    color: #425A8B;
}
.footer-text span{
    font-size: 13px;
    color: #425A8B;
}

.footer-heading {
    color: #0E224D !important;
}

  
  /* ==================================================  Footer ============================*/