.header-main {
    position: relative;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.widget-header {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.bottom-nav-container::-webkit-scrollbar {
   display: none;
  }

.card-body-estore{
    padding: 10px 0px 0px 0px !important;
    text-align: center ;
}

.divider-line{
    border: 1px solid #DDE4F0;
    /* width: 1em -1em; */
}

.estore-backgrund-banner{
    background: url(../images/2.png);
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-size: cover; /* Ensure the image fits within the element without stretching */
    background-position: center; /* Optionally, center the image within the element */

}

.container-about-estore{
    background-color: #79C5CC !important;
    border-radius: 20px !important;
}

.card-estore-company{
    margin: 0px !important;
}

.user-photo-store-card{
    width: 75px;
    height: 75px;
}

/* Round the corners of the Navbar */
.custom-navbar {
    border-radius: 0.375rem; /* Bootstrap's rounded-sm equivalent */
  }
  
  /* Change link color on hover and when active */
  .custom-navbar .nav-link:hover,
  .custom-navbar .nav-link.active {
    color: #FD9636 !important;
  }
  
  /* Optional: Customize active link color when not hovered */
  .custom-navbar .nav-link.active {
    color: #FD9636 !important;
  }

.user-profile-estore-card{
    max-width: 700px;
    margin: 0px auto;
}

.estore-header-details{
    font-size: 14px !important;
}

.widget-header .widget-view {
    padding: 5px 7px;
    display: inline-block;
    position: relative;
    text-align: center;
}

.widget-header .icon-area {
    font-size: 24px;
    display: inline-block;
    width: 36px;
    height: 30px;
    color: #969696;
}

.widget-header .icon-area .notify {
    position: absolute;
    top: 0px;
    right: 8%
}

.widget-header .icon {
    color: #969696;
}

.widget-header .icon .notify {
    position: absolute;
    top: 0;
    right: 0;
}

.widget-header .text {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    line-height: 1.2;
}

.widget-header a {
    color: #212529;
}

.widget-header form {
    min-width: 280px;
}

.widget-header:hover i {
    color: #212529;
}

.sidebar-mobile-widget {
    height: auto;
    color: #555;
}

.container-sidebar-mobile-homepage::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
.container-sidebar-mobile-homepage {
    scrollbar-width: none;
}

.card-slider-items-mobile {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    /* height: 80px; */
    display: inline-block;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 450px;
    padding: 1rem 1rem 0px;
}

.search-page-city-links {
    color: '#fff' !important;
    border-radius: 16px !important;
    background: rgb(69, 172, 179) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Rubik' !important;
}

.search-page-city-links span {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: 'Rubik';
}