
  
  /* Header/Blog Title */
  .header {
    padding: 30px;
    font-size: 40px;
    text-align: center;
    background: white;
  }
  
  /* Create two unequal columns that floats next to each other */
  /* Left column */
  .leftcolumn {
    float: left;
    width: 75%;
  }
  
  /* Right column */
  .rightcolumn {
    float: left;
    width: 25%;
    padding-left: 20px;
  }
  
  /* Fake image */
  .fakeimg {
    background-color: #aaa;
    width: 100%;
    padding: 20px;
  }
  
  /* Add a card effect for articles */
  .card {
    background-color: white;
    padding: 20px;
    margin-top: 20px;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Footer */
  .footer {
    padding: 20px;
    text-align: center;
    background: #ddd;
    margin-top: 20px;
  }
  
  /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 800px) {
    .leftcolumn, .rightcolumn {
      width: 100%;
      padding: 0;
    }
  }
  .thumb-lg {
    height: 88px;
    width: 88px;
    }
    .card {
    border: none;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.05);
    }
    .m-b-30 {
    margin-bottom: 30px;
    }
    .social-links li a {
    -webkit-border-radius: 50%;
    background: #fcfdfd;
    border-radius: 50%;
    color: #9f9f9f;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    }

    /* Background color classes */

.bg-lblue {
  background-color: #3D00B6 !important;
}
/* Button classes */
.btn {
    /* border-radius: 2px; */
	position: relative;
}
.btn.btn-no-border {
	border: 0px !important;
}
/* Button colors */
.btn.btn-white {
	background: #ffffff;
	color: #666666;
	border: 1px solid #dddddd;
}
.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white.active,
.btn.btn-white:active {
	background: #f7f7f7;
	color: #666666;
}
.btn.btn-grey {
	background: #eeeeee;
	color: #666666;
	border: 1px solid #d5d5d5;
}
.btn.btn-grey:hover,
.btn.btn-grey:focus,
.btn.btn-grey.active,
.btn.btn-grey:active {
	background: #d5d5d5;
	color: #999;
}
.btn.btn-black {
	color: #ffffff;
	background: #666666;
	border: 1px solid #4d4d4d;
}
.btn.btn-black:hover,
.btn.btn-black:focus,
.btn.btn-black.active,
.btn.btn-black:active {
	background: #4d4d4d;
	color: #ffffff;
}
.btn.btn-red {
	color: #ffffff;
	background: #ed5441;
	border: 1px solid #e52d16;
}
.btn.btn-red:hover,
.btn.btn-red:focus,
.btn.btn-red.active,
.btn.btn-red:active {
	color: #ffffff;
	background: #e52d16;
}
.btn.btn-green {
	color: #ffffff;
	background: #51d466;
	border: 1px solid #30c247;
}
.btn.btn-green:hover,
.btn.btn-green:focus,
.btn.btn-green.active,
.btn.btn-green:active {
	background: #30c247;
	color: #ffffff;
}
.btn.btn-lblue {
	color: #ffffff;
	background: #32c8de;
	border: 1px solid #1faabe;
}
.btn.btn-lblue:hover,
.btn.btn-lblue:focus,
.btn.btn-lblue.active,
.btn.btn-lblue:active {
	background: #1faabe;
	color: #ffffff;
}
.btn.btn-blue {
	color: #ffffff;
	background: #609cec;
	border: 1px solid #3280e7;
}
.btn.btn-blue:hover,
.btn.btn-blue:focus,
.btn.btn-blue.active,
.btn.btn-blue:active {
	background: #3280e7;
	color: #ffffff;
}
.btn.btn-orange {
	color: #ffffff;
	background: #f8a841;
	border: 1px solid #f69110;
}
.btn.btn-orange:hover,
.btn.btn-orange:focus,
.btn.btn-orange.active,
.btn.btn-orange:active {
	background: #f69110;
	color: #ffffff;
}
.btn.btn-yellow {
	background: #fcd419;
	color: #ffffff;
	border: 1px solid #dfb803;
}
.btn.btn-yellow:hover,
.btn.btn-yellow:focus,
.btn.btn-yellow.active,
.btn.btn-yellow:active {
	background: #dfb803;
	color: #ffffff;
}
.btn.btn-purple {
	background: #cb79e6;
	color: #ffffff;
	border: 1px solid #ba4ede;
}
.btn.btn-purple:hover,
.btn.btn-purple:focus,
.btn.btn-purple.active,
.btn.btn-purple:active {
	background: #ba4ede;
	color: #ffffff;
}
.btn.btn-rose {
	background: #ff61e7;
	color: #ffffff;
	border: 1px solid #ff2edf;
}
.btn.btn-rose:hover,
.btn.btn-rose:focus,
.btn.btn-rose.active,
.btn.btn-rose:active {
	background: #ff2edf;
	color: #ffffff;
}
.btn.btn-brown {
	background: #d08166;
	color: #ffffff;
	border: 1px solid #c4613f;
}
.btn.btn-brown:hover,
.btn.btn-brown:focus,
.btn.btn-brown.active,
.btn.btn-brown:active {
	background: #c4613f;
	color: #ffffff;
}

.shop-items{
	
	margin:10px auto;
	padding:0px 20px;
}
.shop-items .item {
	position: relative;
	max-width: 260px;
	margin: 15px auto;
	padding: 5px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	border:2px solid #eee;
}
.shop-items .item:hover{	
	border:2px solid #32c8de;
}
.shop-items .item img {
	width: 100%;
	max-width: 360px;
	margin: 0 auto;
	border: 1px solid #eee;
	border-radius: 3px;
}
.shop-items .item  .item-dtls h4 {
	margin-top: 13px;
	margin-bottom: 10px;
	text-transform: uppercase;
}
.shop-items .item  .item-dtls .price {
	display: block;
	margin-bottom: 13px;
	font-size: 25px;
}
.shop-items .item  .ecom {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding-bottom:10px;
	padding-top: 10px;
	-webkit-transition: all 0.35s ease-in;
	-moz-transition: all 0.35s ease-in;
	-ms-transition: all 0.35s ease-in;
	-o-transition: all 0.35s ease-in;
	transition: all 0.35s ease-in;
}
.shop-items .item:hover  .ecom { 
	margin-top: -50px; 
}
.shop-items .item  .ecom  a.btn{
	border:1px solid #fff;
	color:#fff;
	background:transparent;
	-webkit-transition: all 0.35s ease-in;
	-moz-transition: all 0.35s ease-in;
	-ms-transition: all 0.35s ease-in;
	-o-transition: all 0.35s ease-in;
	transition: all 0.35s ease-in;
}
.shop-items .item  .ecom  a.btn:hover{
	background:#fff;
	color:#777;
} 


