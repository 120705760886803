
  .carousel {
    display: block;
    text-align: left;
    position: relative;
  }
  .carousel > input {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
  .carousel > input:nth-of-type(6):checked ~ .carousel__slides .carousel__slide:first-of-type {
    margin-left: -500%;
  }
  .carousel > input:nth-of-type(5):checked ~ .carousel__slides .carousel__slide:first-of-type {
    margin-left: -400%;
  }
  .carousel > input:nth-of-type(4):checked ~ .carousel__slides .carousel__slide:first-of-type {
    margin-left: -300%;
  }
  .carousel > input:nth-of-type(3):checked ~ .carousel__slides .carousel__slide:first-of-type {
    margin-left: -200%;
  }
  .carousel > input:nth-of-type(2):checked ~ .carousel__slides .carousel__slide:first-of-type {
    margin-left: -100%;
  }
  .carousel > input:nth-of-type(1):checked ~ .carousel__slides .carousel__slide:first-of-type {
    margin-left: 0%;
  }
  .carousel > input:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 255, 0.5);
  }
  .carousel > input:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 255, 0.5);
  }
  .carousel > input:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 255, 0.5);
  }
  .carousel > input:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 255, 0.5);
  }
  .carousel > input:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 255, 0.5);
  }
  .carousel > input:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 255, 0.5);
  }
  .carousel__slides {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    display: flex;
  }
  .carousel__slide {
    position: relative;
    display: block;
    flex: 1 0 100%;
    width: 100%;
    height: 400px;
    overflow: hidden;
    transition: all 300ms ease-out;
  
    box-sizing: border-box;
    white-space: normal;
  }
  .carousel__slide figure {
    display: flex;
    margin: 0;
  }
  .carousel__slide div {
    position: relative;
    width: 100%;
  }
  .carousel__slide div:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 66.6666666667%;
  }
  .carousel__slide div > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .carousel__slide img {
    display: block;
    flex: 1 1 auto;
    object-fit: cover;
  }
  .carousel__slide figcaption {
    align-self: flex-end;
    padding: 20px 20px 0 20px;
    flex: 0 0 auto;
    width: 25%;
    min-width: 150px;
  }
  .carousel__slide .credit {
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.5);
    display: block;
  }
  .carousel__slide.scrollable {
    overflow-y: scroll;
  }
  .carousel__thumbnails {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin: 0 -10px;
  }
  .carousel__slides + .carousel__thumbnails {
    margin-top: 20px;
  }
  .carousel__thumbnails li {
    flex: 1 1 auto;
    max-width: calc((100% / 6) - 20px);
    margin: 0 10px;
    transition: all 300ms ease-in-out;
  }
  .carousel__thumbnails label {
    display: block;
    position: relative;
  }
  .carousel__thumbnails label:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .carousel__thumbnails label > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .carousel__thumbnails label:hover, .carousel__thumbnails label:focus {
    cursor: pointer;
  }
  .carousel__thumbnails label:hover img, .carousel__thumbnails label:focus img {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
    transition: all 300ms ease-in-out;
  }
  .carousel__thumbnails img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* 2nd start */
  

.profile-header {
    position: relative;
    overflow: hidden
}

.profile-header .profile-header-cover {
    /* background-color: #00b5ec; */
  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

.profile-header .profile-header-cover:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .75) 100%)
}

.profile-header .profile-header-content {
    color: #fff;
    padding: 25px;
    padding-top: 200px;
}

.profile-header-img {
    float: left;
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: relative;
    z-index: 10;
    margin: 0 0 -20px;
    padding: 3px;
    border-radius: 4px;
    background: #fff
}

.profile-header-img img {
    max-width: 100%
}

.profile-header-info h4 {
    font-weight: 500;
    color: #fff
}

.profile-header-img+.profile-header-info {
    margin-left: 140px
}

.profile-header .profile-header-content,
.profile-header .profile-header-tab {
    position: relative
}

.b-minus-1,
.b-minus-10,
.b-minus-2,
.b-minus-3,
.b-minus-4,
.b-minus-5,
.b-minus-6,
.b-minus-7,
.b-minus-8,
.b-minus-9,
.b-plus-1,
.b-plus-10,
.b-plus-2,
.b-plus-3,
.b-plus-4,
.b-plus-5,
.b-plus-6,
.b-plus-7,
.b-plus-8,
.b-plus-9,
.l-minus-1,
.l-minus-2,
.l-minus-3,
.l-minus-4,
.l-minus-5,
.l-minus-6,
.l-minus-7,
.l-minus-8,
.l-minus-9,
.l-plus-1,
.l-plus-10,
.l-plus-2,
.l-plus-3,
.l-plus-4,
.l-plus-5,
.l-plus-6,
.l-plus-7,
.l-plus-8,
.l-plus-9,
.r-minus-1,
.r-minus-10,
.r-minus-2,
.r-minus-3,
.r-minus-4,
.r-minus-5,
.r-minus-6,
.r-minus-7,
.r-minus-8,
.r-minus-9,
.r-plus-1,
.r-plus-10,
.r-plus-2,
.r-plus-3,
.r-plus-4,
.r-plus-5,
.r-plus-6,
.r-plus-7,
.r-plus-8,
.r-plus-9,
.t-minus-1,
.t-minus-10,
.t-minus-2,
.t-minus-3,
.t-minus-4,
.t-minus-5,
.t-minus-6,
.t-minus-7,
.t-minus-8,
.t-minus-9,
.t-plus-1,
.t-plus-10,
.t-plus-2,
.t-plus-3,
.t-plus-4,
.t-plus-5,
.t-plus-6,
.t-plus-7,
.t-plus-8,
.t-plus-9 {
    position: relative!important
}

.profile-header .profile-header-tab {
    background: #fff;
    list-style-type: none;
    margin: -10px 0 0;
    padding: 0 0 0 140px;
    white-space: nowrap;
    border-radius: 0
}

.text-ellipsis,
.text-nowrap {
    white-space: nowrap!important
}

.profile-header .profile-header-tab>li {
    display: inline-block;
    margin: 0
}

.profile-header .profile-header-tab>li>a {
    display: block;
    color: #929ba1;
    line-height: 20px;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    border: none
}

.profile-header .profile-header-tab>li.active>a,
.profile-header .profile-header-tab>li>a.active {
    color: #242a30
}

.profile-content {
    padding: 25px;
    border-radius: 4px
}

.profile-content:after,
.profile-content:before {
    content: '';
    display: table;
    clear: both
}

.profile-content .tab-content,
.profile-content .tab-pane {
    background: 0 0
}

.profile-left {
    width: 200px;
    float: left
}

.profile-right {
    margin-left: 240px;
    padding-right: 20px
}

.profile-image {
    height: 175px;
    line-height: 175px;
    text-align: center;
    font-size: 72px;
    margin-bottom: 10px;
    border: 2px solid #E2E7EB;
    overflow: hidden;
    border-radius: 4px
}

.profile-image img {
    display: block;
    max-width: 100%
}

.profile-highlight {
    padding: 12px 15px;
    background: #FEFDE1;
    border-radius: 4px
}

.profile-highlight h4 {
    margin: 0 0 7px;
    font-size: 12px;
    font-weight: 700
}

.table.table-profile>thead>tr>th {
    border-bottom: none!important
}

.table.table-profile>thead>tr>th h4 {
    font-size: 20px;
    margin-top: 0
}

.table.table-profile>thead>tr>th h4 small {
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px
}

.table.table-profile>tbody>tr>td,
.table.table-profile>thead>tr>th {
    border: none;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #242a30;
    background: 0 0
}

.table.table-profile>tbody>tr>td.field {
    width: 20%;
    text-align: right;
    font-weight: 600;
    color: #2d353c
}

.table.table-profile>tbody>tr.highlight>td {
    border-top: 1px solid #b9c3ca;
    border-bottom: 1px solid #b9c3ca
}

.table.table-profile>tbody>tr.divider>td {
    padding: 0!important;
    height: 10px
}

.profile-section+.profile-section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #b9c3ca
}

.profile-section:after,
.profile-section:before {
    content: '';
    display: table;
    clear: both
}

.profile-section .title {
    font-size: 20px;
    margin: 0 0 15px
}

.profile-section .title small {
    font-weight: 400
}

body.flat-black {
    background: #E7E7E7
}

.flat-black .navbar.navbar-inverse {
    background: #212121
}

.flat-black .navbar.navbar-inverse .navbar-form .form-control {
    background: #4a4a4a;
    border-color: #4a4a4a
}

.flat-black .sidebar,
.flat-black .sidebar-bg {
    background: #3A3A3A
}

.flat-black .page-with-light-sidebar .sidebar,
.flat-black .page-with-light-sidebar .sidebar-bg {
    background: #fff
}

.flat-black .sidebar .nav>li>a {
    color: #b2b2b2
}

.flat-black .sidebar.sidebar-grid .nav>li>a {
    border-bottom: 1px solid #474747;
    border-top: 1px solid #474747
}

.flat-black .sidebar .active .sub-menu>li.active>a,
.flat-black .sidebar .nav>li.active>a,
.flat-black .sidebar .nav>li>a:focus,
.flat-black .sidebar .nav>li>a:hover,
.flat-black .sidebar .sub-menu>li>a:focus,
.flat-black .sidebar .sub-menu>li>a:hover,
.sidebar .nav>li.nav-profile>a {
    color: #fff
}

.flat-black .sidebar .sub-menu>li>a,
.flat-black .sidebar .sub-menu>li>a:before {
    color: #999
}

.flat-black .page-with-light-sidebar .sidebar .active .sub-menu>li.active>a,
.flat-black .page-with-light-sidebar .sidebar .active .sub-menu>li.active>a:focus,
.flat-black .page-with-light-sidebar .sidebar .active .sub-menu>li.active>a:hover,
.flat-black .page-with-light-sidebar .sidebar .nav>li.active>a,
.flat-black .page-with-light-sidebar .sidebar .nav>li.active>a:focus,
.flat-black .page-with-light-sidebar .sidebar .nav>li.active>a:hover {
    color: #000
}

.flat-black .page-sidebar-minified .sidebar .nav>li.has-sub:focus>a,
.flat-black .page-sidebar-minified .sidebar .nav>li.has-sub:hover>a {
    background: #323232
}

.flat-black .page-sidebar-minified .sidebar .nav li.has-sub>.sub-menu,
.flat-black .sidebar .nav>li.active>a,
.flat-black .sidebar .nav>li.active>a:focus,
.flat-black .sidebar .nav>li.active>a:hover,
.flat-black .sidebar .nav>li.nav-profile,
.flat-black .sidebar .sub-menu>li.has-sub>a:before,
.flat-black .sidebar .sub-menu>li:before,
.flat-black .sidebar .sub-menu>li>a:after {
    background: #2A2A2A
}

.flat-black .page-sidebar-minified .sidebar .sub-menu>li:before,
.flat-black .page-sidebar-minified .sidebar .sub-menu>li>a:after {
    background: #3e3e3e
}

.flat-black .sidebar .nav>li.nav-profile .cover.with-shadow:before {
    background: rgba(42, 42, 42, .75)
}

.bg-white {
    background-color: #fff!important;
}
.p-10 {
    padding: 10px!important;
}
.media.media-xs .media-object {
    width: 32px;
}
.m-b-2 {
    margin-bottom: 2px!important;
}
.media>.media-left, .media>.pull-left {
    padding-right: 15px;
}
.media-body, .media-left, .media-right {
    display: table-cell;
    vertical-align: top;
}
select.form-control:not([size]):not([multiple]) {
    height: 40px;
}
.form-control.input-inline {
    display: inline;
    width: auto;
    padding: 0 7px;
}
.pointer{
  cursor: pointer;
}




/* .card {
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%);
  margin-bottom: 24px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #ecf2f5;
  border-radius: .25rem;
} */
.avatar-lg {
  height: 4.5rem;
  width: 4.5rem;
}
.rounded-circle {
  border-radius: 50%!important;
}
.img-thumbnail {
  padding: .25rem;
  background-color: #ecf2f5;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto;
}
.avatar-sm {
  height: 2.25rem;
  width: 2.25rem;
}
.rounded-circle {
  border-radius: 50%!important;
}
.me-2 {
  margin-right: .75rem!important;
}
.avatar-md {
  height: 3.5rem;
  width: 3.5rem;
}
.rounded-circle {
  border-radius: 50%!important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent!important;
}
.post-user-comment-box {
  background-color: #f2f8fb;
  margin: 0 -.75rem;
  padding: 1rem;
  margin-top: 20px;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit!important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}
.font-13 {
  font-size: 13px!important;
}
.btn-soft-info {
  color: #45bbe0;
  background-color: rgba(69,187,224,.18);
  border-color: rgba(69,187,224,.12);
}
.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;
}
.comment-area-box .comment-area-btn {
  background-color: #f2f8fb;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-top: none;
  border-radius: 0 0 .2rem .2rem;
}